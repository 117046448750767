import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import { View, Card, Text, Button, Spacer } from "ollui";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import { Author } from "./Content";

class BlogRoll extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <View extend={rootCSS}>
        {posts &&
          posts.map(({ node: post }) => (
            <Card as="article" key={post.id} extend={postCSS}>
              <View extend={imageCSS}>
                {post.frontmatter.featuredimage ? (
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: post.frontmatter.featuredimage,
                      alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                    }}
                  />
                ) : null}
              </View>
              <View extend={infoCSS}>
                <Link to={post.fields.slug}>
                  <Text
                    variant="medium"
                    bold
                    extend={{ textDecoration: "none" }}
                  >
                    {post.frontmatter.title}
                  </Text>
                </Link>
                <Spacer size={2} />
                <Author date={post.frontmatter.date} />
                <Spacer size={2} />
                <Text>{post.frontmatter.description}</Text>
                <Spacer size={3} />
                <Button
                  as={Link}
                  className="button"
                  to={post.fields.slug}
                  extend={{ textDecoration: "none", marginLeft: "auto" }}
                  variant="clear"
                >
                  Keep Reading →
                </Button>
              </View>
            </Card>
          ))}
      </View>
    );
  }
}

const rootCSS = ({ theme: { grid, layout, breakpoints } }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gridGap: 4 * grid,
  width: "100%",
  maxWidth: layout.maxContentWidth,
  margin: "0 auto",
  padding: 4 * grid,

  [breakpoints.onlyS]: {
    gridTemplateColumns: "1fr",
  },
});

const postCSS = ({ theme: { grid, colors } }) => ({
  display: "flex",
  flexDirection: "column",
  // padding: 2 * grid,
  padding: 0,
  overflow: "hidden",
  border: `2px solid ${colors.border}`,
  // height: 300,
  // marginBottom: 4 * grid,
});

const imageCSS = ({ theme: { grid, colors } }) => ({
  width: "100%",
  // flexShrink: 0,
});

const infoCSS = ({ theme: { grid } }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: 3 * grid,
  textDecoration: "none",
});

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                description
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 120, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
);
