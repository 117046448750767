import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { View, Text, Button, Spacer } from "ollui";

import Layout from "../components/Layout";
import BlogRoll from "../components/BlogRoll";
import { Pricing, Features, FAQ, StartTrial, Hero } from "../sections";

export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => (
  <View extend={rootCSS}>
    <Hero title={title} subheading={subheading} />
    {/* <View extend={screenshotCSS}>
      <View as="img" src={screenshot} extend={screenshotImageCSS} />
    </View> */}
    <Features />
    {/* <Pricing /> */}
    <FAQ />
    <StartTrial />
  </View>
);

const rootCSS = ({ theme: { grid, layout } }) => ({
  // padding: 4 * grid,
  paddingTop: 0,
  maxWidth: layout.maxContentWidth,
  margin: "0 auto",
});

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        description
      }
    }
  }
`;
