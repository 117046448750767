import React from "react";
import PropTypes from "prop-types";
import { View, Text, getFormattedDate, Spacer } from "ollui";
import profile from "../img/olle.png";

export const Author = ({ date }) => (
  <View extend={authorCSS}>
    <View extend={authorImageCSS} as="img" src={profile}></View>
    <Spacer size={2} />
    <View extend={authorInfoCSS}>
      <Text bold>Olle Svensson</Text>
      <Text diffuse>{getFormattedDate("ago", date)}</Text>
    </View>
  </View>
);

export const HTMLContent = ({ content, title, displayAuthor, date }) => (
  <View extend={rootCSS}>
    <View extend={titleCSS}>
      <Text variant="title">{title}</Text>
    </View>
    {displayAuthor && date && (
      <>
        <Author date={date} />
        <Spacer size={6} />
      </>
    )}
    <View extend={contentCSS} dangerouslySetInnerHTML={{ __html: content }} />
  </View>
);

const Content = ({ content, className }) => (
  <div className={className}>{content}</div>
);

const rootCSS = ({ theme: { grid, layout } }) => ({
  marginTop: 6 * grid,
  padding: 4 * grid,
  paddingTop: 0,
  maxWidth: 800,
  margin: "0 auto",
});

const titleCSS = ({ theme: { grid, colors, radius, breakpoints } }) => ({
  width: "100%",
  // textAlign: "center",
  paddingTop: 10 * grid,
  paddingBottom: 4 * grid,
  // background: colors.contentBg,
  borderRadius: radius,
  borderBottom: `2px solid ${colors.border}`,
  marginBottom: 2 * grid,

  [breakpoints.onlyS]: {
    paddingTop: 4 * grid,
  },
});

const authorCSS = ({ theme: { grid } }) => ({
  // background: "hotpink",
  display: "flex",
  // marginBottom: 6 * grid,
  // marginTop: -2 * grid,
});

const authorImageCSS = ({ theme: { colors, shadows } }) => ({
  width: 50,
  height: 50,
  borderRadius: "50%",
  boxShadow: shadows.big,
  border: `4px solid ${colors.border}`,
  // background: "red",
});

const authorInfoCSS = () => ({
  // background: "hotpink",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

const contentCSS = ({ theme: { grid, colors, radius, shadows } }) => ({
  // background: "hotpink",
  "& > p": {
    margin: `${2 * grid}px 0`,
    fontSize: 16,
    lineHeight: "32px",
    textAlign: "justify",
  },
  "& > h1,h2,h3": {
    marginTop: 5 * grid,
    marginBottom: grid,
  },

  "& > p > a": {
    color: colors.primary,
    "&:hover": {
      color: colors.primaryDark,
    },
  },

  "& > h1": {
    // color: "hotpink",
    fontSize: 28,
    marginBottom: 2 * grid,
  },

  "& > h2": {
    // fontWeight: "bold",
    fontSize: 24,
    marginBottom: 2 * grid,
  },

  "& > h3": {
    // color: "blue",
    fontWeight: "bold",
    fontSize: 18,
  },

  "& > ul,ol": {
    listStyle: "square",
    margin: `${3 * grid}px 0`,
    paddingLeft: 4 * grid,

    "& > li": {
      lineHeight: "32px",
      fontSize: 16,
    },
  },

  "& > p > img": {
    width: "100%",
    borderRadius: 30,
    boxShadow: shadows.big,
    margin: `${4 * grid}px 0`,
  },
});

Content.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
};

HTMLContent.propTypes = Content.propTypes;

export default Content;
